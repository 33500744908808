.campaigns-wrapper {
  min-height: 100%;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.1);
  border-radius: 2px;
}
.campaigns-wrapper .nav-pills .nav-link.active {
  background-color: #43a39f;
  color: white;
}
.campaigns-wrapper .nav-pills .nav-link {
  text-transform: capitalize;
  color: #333;
  border-radius: 2px;
}
.campaigns-wrapper .dashboard-content,
.campaigns-wrapper .nav {
  min-height: 100%;
  width: fit-content;
}
.form-floating .form-control.select-email,
.form-control.select-email {
  padding: 0 !important;
  position: relative;
  z-index: 10;
}
.form-control.select-email > div {
  position: static !important;
}
.form-control.select-email * {
  border: none !important;
  box-shadow: none;
  transition: 0.2s;
}
.form-control.select-email #react-select-3-listbox {
  margin: 0 !important;
  background-color: white !important;
  z-index: 11 !important;
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  width: 100% !important;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}
.form-control.select-email .css-t3ipsp-control:focus-visible,
.form-control.select-email .css-t3ipsp-control:hover,
.form-control.select-email .css-3w2yfm-ValueContainer,
.form-control.select-email .css-13cymwt-control {
  height: 100% !important;
  border-width: 0 !important;
  border-color: none !important;
}
.css-1nmdiq5-menu {
  z-index: 9999999 !important;
}

.all-contacts {
  max-width: 100%;
}
.all-contacts .tableSearch {
  max-width: 300px;
}
.all-contacts th span,
.all-contacts
  .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon {
  color: white !important;
}
.all-contacts p {
  margin: 4px 0 5px 0 !important;
}

@media (max-width: 575.98px) {
  .formMain .addFormHeading {
    font-size: 18px;
  }
  .demosEditSection .demosEditList .stepstriggers {
    width: 30px;
    height: 30px;

    font-size: 12px;
  }
  .campaigns-wrapper h5,
  .formMain.demosEditSection form h5 {
    font-size: 18px;
  }
  .formMain.demosEditSection .clickable.add-button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    font-size: 12px;
    width: 22px;
    min-height: 22px;
    min-width: 22px;
  }
  .form-floating .css-b62m3t-container.form-control,
  .form-floating select.form-control,
  .form-floating input.form-control {
    max-height: 40px !important;
    min-height: 40px !important;
    font-size: 10px;
  }
  .form-floating > label {
    padding: 0.75rem;
    font-size: 10px;
  }
  small.text-end.text-dark {
    font-size: 10px;
  }

  .campaigns-wrapper .nav-pills .nav-link {
    font-size: 12px;
    padding: 0.3rem 0.5rem;
  }

  .table-responsive .striped th {
    font-size: 14px;
    padding: 0.25rem 0.5rem;
  }
  .table-responsive .striped td {
    font-size: 12px;
    padding: 0.25rem 0.5rem;
  }
}
