.add-button.rounded-circle,
.fieldset .actionDltBtn {
  border-radius: 50% !important;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-button.rounded-circle {
  font-size: 20px;
}
