.dashboardSec.admin .dashboard-nav {
  max-width: 144px;
}

.dashboardSec .accordion-item .accordion-button {
  flex-direction: row-reverse;
  justify-content: start;
  align-items: start !important;
  padding: 12px 6px !important;
  border-radius: 0 !important;
  gap: 6px;
}

.dashboardSec .accordion-item .accordion-button span::before {
  content: none !important;
}

.dashboardSec .accordion-item .accordion-button:not(.collapsed) {
  color: #42a49f !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.dashboardSec .accordion-item .accordion-button:not(.collapsed) * {
  color: #42a49f !important;
}

.dashboardSec .accordion-item .accordion-button::after {
  display: block;
  position: static !important;
  margin-left: 0 !important;
  margin-top: 4px;
  width: 14px !important;
  height: 14px !important;
  background-size: 14px !important;
}

.dashboardSec .accordion-item .accordion-button.collapsed::after {
  transform: rotate(-90deg) !important;
}

.dashboardSec .accordion-item .accordion-button:not(.collapsed)::after {
  transform: rotate(0deg) !important;
}

.dashboardSec .accordion-item .accordion-body .nav-item .nav-link {
  padding-left: 20px;
  width: fit-content;
}

.dashboardSec .accordion-item .accordion-body .nav-item .nav-link svg {
  font-size: 20px;
}

.dashboardSec.admin .dashboard-nav .nav-link:hover::before,
.dashboardSec.admin .dashboard-nav .nav-link.active::before {
  transform: none;
  width: 50%;
  left: 21px;
  bottom: -2px;
}
.dashboardSec.admin.super .dashboard-nav .nav-link:hover::before,
.dashboardSec.admin.super .dashboard-nav .nav-link.active::before {
  left: 2px !important;
}
/*  */

.config-page .api_integrations .header {
  display: none;
}

.config-page .api_integrations .demosViewMoreForm {
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100%;
  box-shadow: none !important;
}
