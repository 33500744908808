.add-startup-popup .item-list-img .form-label,
.add-startup-popup .list-img-wrap {
  position: static !important;
  margin-top: 0 !important;
}
.add-startup-popup .imgBanner {
  border-radius: 12px;
  overflow: hidden;
}
.add-startup-popup .uploaded-thumb-edit {
  background-color: #fff !important;
  border: 2px dashed #dddddd;
}
.item-list-img .form-label,
.item-hero-img .form-label {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: start;
  justify-content: end;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 2px solid white;
  cursor: pointer;
}

.list-img-wrap {
  position: relative !important;
  left: 30%;
  margin-top: -12%;
}

.item-hero-img .imagePreview {
  font-size: 12px;
  text-align: end;
}

.item-list-img .imagePreview {
  display: none;
}
.member-profile-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  gap: 16px;
  border: 2px dashed #dddddd;
  height: 240px;
  max-width: 240px;
  width: 100%;
}

.btn.add-member-btn {
  font-size: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333;
  height: 240px;
  max-width: 240px;
  width: 100%;
  border-radius: 12px;
  border: 2px dashed #dddddd;
  transition: all 0.3s;
}
/* 
.btn.add-member-btn .member-thumb {
  height: 160px;
  width: 160px;
} */

.btn.add-member-btn:hover {
  border: 2px dashed #dddddd !important;
  opacity: 0.75 !important;
}
.btn.add-member-btn:hover svg {
  scale: 1.1;
}

.modal-profile-img {
  height: 180px;
  object-fit: cover;
}

.social a {
  display: block;
  width: 34px;
  height: 34px;
  font-size: 18px;
  border-radius: 50%;
  border: 1px solid #43a39f;
  color: #43a39f;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 575.98px) {
  .social a {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}
