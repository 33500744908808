.heroSection,
.swiper {
  width: 100%;
  height: 100vh;
}

.heroSection .swiper-slide {
  background-position: center;
  background-size: cover;
  height: 100%;
}

.heroSection .swiper-slide img {
  display: block;
  width: 100%;
}
.heroSection .swiper-pagination-bullets {
  bottom: 26px;
}
.heroSection .swiper-pagination-clickable .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s;
}
.heroSection
  .swiper-pagination-clickable
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 20px;
  transition: all 0.3s;
}
.heroSection .heroContentWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.heroContent {
  max-width: 620px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.heroSection h1 {
  font-size: 50px;
  color: #fff;
  font-weight: 800;
  font-family: "Unbounded", sans-serif;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.heroSection h1 span {
  color: #42a49f;
}
.heroSection p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 30px;
  opacity: 0.8;
}
.subscribe .inputGroup,
.heroSection .inputGroup {
  flex-wrap: nowrap;
  border-radius: 6px;
  overflow: hidden;
  padding: 5px;
  background: #92929258;
  border: 2px solid #aaaaaa8a;
  backdrop-filter: blur(4px);
  box-shadow: rgba(85, 87, 88, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
}
.subscribe .inputGroup {
  backdrop-filter: none;
}
.heroSection .searchNote {
  font-size: 14px;
}
.subscribe .inputGroup .form-control,
.heroSection .inputGroup .form-control {
  background: transparent;
  box-shadow: unset;
  border: 1px solid transparent;
  color: #fff;
  min-height: 40px;
  max-height: 40px;
  flex-grow: 1;
}
.subscribe .inputGroup .form-control:focus,
.heroSection .inputGroup .form-control:focus {
  border: none;
  box-shadow: none;
}
.subscribe .inputGroup .form-control::placeholder {
  color: #666;
}
.heroSection .inputGroup .form-control::placeholder {
  color: #ccc;
}
.subscribe .input-group-text,
.heroSection .input-group-text {
  border: none !important;
  border-radius: 50% !important;
  background: #42a49f !important;
  color: #fff !important;
  height: 42px;
  width: 42px;
  justify-content: center;
}
.subscribe .inputGroup .form-control,
.subscribe .input-group-text {
  color: #333;
}
@media (max-width: 1440.98px) {
  .heroSection h1 {
    font-size: 42px;
  }
  .subscribe .inputGroup .form-control,
  .heroSection .inputGroup .form-control {
    min-height: 40px !important;
    max-height: 40px !important;
  }
  .subscribe .input-group-text,
  .heroSection .input-group-text {
    height: 40px;
    width: 40px;
  }
  .subscribe .inputGroup,
  .heroSection .inputGroup {
    padding: 4px;
  }
}
@media screen and (max-width: 1199.98px) {
  .heroSection h1 {
    font-size: 38px;
  }
}
@media screen and (max-width: 767.98px) {
  .heroSection,
  .swiper {
    height: 80vh;
  }
  .heroSection h1 {
    font-size: 32px;
  }
  .heroSection .searchNote {
    font-size: 12px;
  }
  .subscribe .inputGroup .form-control,
  .heroSection .inputGroup .form-control {
    padding: 0 8px !important;
    min-height: 36px !important;
    max-height: 36px !important;
    font-size: 14px;
  }
  .subscribe .input-group-text,
  .heroSection .input-group-text {
    height: 36px;
    width: 36px;
  }
  .subscribe .inputGroup .form-control::placeholder,
  .heroSection .inputGroup .form-control::placeholder {
    font-style: 12px !important;
    font-weight: normal !important;
  }
  .subscribe .input-group-text,
  .heroSection .input-group-text {
    height: 38px;
    width: 38px;
  }
  .heroSection p {
    font-size: 16px;
    line-height: normal;
    margin-top: 8px;
  }
}
@media screen and (max-width: 575.98px) {
  .heroSection h1 {
    font-size: 24px;
  }
  .heroSection p {
    font-size: 14px;
  }
  .heroSection .inputGroup .form-control::placeholder {
    font-size: 12px !important;
  }
  .heroSection .inputGroup .form-control {
    padding: 0 6px !important;
    min-height: 28px !important;
    max-height: 28px !important;
  }
  .heroSection .input-group-text {
    height: 28px;
    width: 28px;
    padding: 6px;
    font-size: 12px;
  }
  .heroSection .swiper-pagination-clickable .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
  }
  .heroSection
    .swiper-pagination-clickable
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 10px;
  }
}
