@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --bs-theme: #43a39f;
}

input:focus-visible,
input:focus {
  border-color: inherit;
  box-shadow: none;
  outline: none;
}
input:focus-visible,
input:focus {
  border-color: inherit;
  box-shadow: none;
  outline: none;
}
.loader-false {
  z-index: 99999;
}
.cursor-pointer {
  cursor: pointer !important;
}
.wrapper-desc {
  max-width: 400px;
}

.text-overflow-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.text-overflow-desc-sm {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-overflow-desc-xs {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-fit {
  width: fit-content !important;
}
.circle-image img {
  width: 50px;
  height: 50px;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "Unbounded", sans-serif;
}
p,
label {
  font-family: "Poppins", sans-serif;
}
.btn:hover {
  color: initial;
  background-color: inherit;
  border-color: transparent;
}
.form-control:focus {
  background-color: transparent !important;
  z-index: inherit !important;
  box-shadow: none !important;
  border-color: #5ea19e !important;
}

/* **React confirm alert** */
.react-confirm-alert {
  max-width: 380px;
  width: 100%;
}
.react-confirm-alert-overlay {
  background-color: rgb(42 42 42 / 60%) !important;
  z-index: 99999 !important;
}

.react-confirm-alert-body {
  font-family: "Unbounded", sans-serif !important;
  width: 100% !important;
  max-width: 500px !important;
  border-radius: 6px !important;
  font-size: 12px;
  color: rgb(228, 10, 10) !important;
  padding: 16px !important;
}
.react-confirm-alert-body > h1 {
  font-family: "Unbounded", sans-serif !important;
  font-size: 20px;
  color: #000;
  margin: 4px 0 !important;
}
.react-confirm-alert-button-group {
  justify-content: end !important;
  align-items: center !important;
  margin-top: 32px !important;
  gap: 10px;
}
.react-confirm-alert-button-group > button {
  margin: 0 !important;
  text-align: center !important;
  padding: 6px 30px !important;
  border-radius: 4px !important;
  opacity: 0.75;
  transition: all 0.3s;
}
.react-confirm-alert-button-group > button:hover {
  color: white !important;
  opacity: 1;
}

.react-confirm-alert-button-group > button:nth-of-type(1) {
  background-color: rgb(228, 10, 10);
}
.react-confirm-alert-button-group > button:nth-of-type(2) {
  background-color: #42a49f;
}
@media screen and (max-width: 575.68px) {
  .react-confirm-alert-body {
    font-size: 12px;
    border-radius: 2px !important;
  }
  .react-confirm-alert-body > h1 {
    font-size: 22px;
    margin-bottom: 0;
  }
  .react-confirm-alert-button-group > button {
    padding: 7px 22px !important;
    border-radius: 2px !important;
    font-size: 11px !important;
  }

  .react-confirm-alert-button-group {
    margin-top: 22px !important;
  }
}
