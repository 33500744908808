.code-generation-offcanvas.chatAI .inputGroup {
  border: 1px solid #00000022 !important;
  border-radius: 8px !important;
  padding-left: 14px !important;
}
.code-generation-offcanvas.chatAI .inputGroup .form-control::placeholder,
.code-generation-offcanvas.chatAI .inputGroup input.form-control {
  font-size: 14px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0px !important;
}
.code-generation-offcanvas.chatAI .inputGroup input.form-control {
  min-height: 36px !important;
  max-height: 36px !important;
}
.code-generation-offcanvas.chatAI .inputGroup textarea.form-control {
  max-height: 250px !important;
}

.code-generation-offcanvas.chatAI .input-group-text {
  height: 36px !important;
  width: 36px !important;
  padding: 0 !important;
}

.code-generation-offcanvas {
  max-width: 768px;
  width: 100% !important;
}
