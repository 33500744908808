/* .footerSection */
.footerSection {
  background-color: #35797512;
  border-top: 1px solid #0a464c42;
  padding-top: 50px;
}
.footerSection .footer-logo img {
  width: 200px;
  height: 98px;
  object-fit: contain;
}
.footerSection p {
  font-size: 14px;
  color: #222;
  font-weight: 500;
}
.footerSection .nav-link {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.footerSection .footerForm h5 {
  font-size: 18px;
}
.subscribe-form .inputGroup,
.footerSection .inputGroup {
  border-radius: 6px;
  overflow: hidden;
  padding: 4px;
  border: 1px solid #7b7b7b58;
}
.subscribe-form .inputGroup .form-control,
.footerSection .inputGroup .form-control {
  background: transparent;
  font-weight: normal;
  border: none;
  color: #111;
  min-height: 38px !important;
  height: 38px !important;
  font-size: 14px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.subscribe-form .inputGroup .form-control:focus,
.footerSection .inputGroup .form-control:focus {
  border: none;
}
.subscribe-form .inputGroup .form-control::placeholder,
.footerSection .inputGroup .form-control::placeholder {
  font-weight: 400 !important;
  color: #2444;
}
.subscribe-form .input-group-text,
.footerSection .input-group-text {
  border: none !important;
  border-radius: 50% !important;
  background: #42a49f !important;
  color: #fff !important;
  height: 38px;
  width: 38px;
  justify-content: center;
}
.social-list a {
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 50%;
  color: #42a49f;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #42a49f;
}

.footerSection .copyright {
  background-color: #42a49f;
  margin-top: 40px;
  padding: 12px;
}
@media screen and (max-width: 767.98px) {
  .footerSection {
    padding-top: 40px;
  }
  .footerSection .footer-logo img {
    width: 170px;
    height: 72px;
  }
  .footerSection p {
    font-size: 12px;
  }
  .footerSection .nav-link {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .footerForm h4 {
    font-size: 18px;
  }
  .subscribe-form .inputGroup,
  .footerSection .inputGroup {
    padding: 4px;
  }
  .subscribe-form .input-group-text,
  .footerSection .input-group-text {
    height: 34px;
    width: 34px;
  }
  .subscribe-form .inputGroup .form-control,
  .footerSection .inputGroup .form-control {
    min-height: 34px !important;
    height: 34px !important;
  }
  .subscribe-form .inputGroup .form-control::placeholder,
  .footerSection .inputGroup .form-control::placeholder {
    font-size: 14px !important;
  }
  .footerSection .footerForm h5 {
    font-size: 16px;
  }
}
@media screen and (max-width: 575.98px) {
  .footerSection .footerForm h5 {
    margin-bottom: 0.5rem;
  }
  .subscribe-form .input-group-text,
  .footerSection .input-group-text {
    height: 28px;
    width: 28px;
    padding: 0;
    font-size: 12px;
  }
  .footerSection .inputGroup .form-control {
    min-height: 28px !important;
    height: 28px !important;
  }
  .footerSection .copyright {
    padding: 8px;
  }
  .footerSection p {
    font-size: 10px;
  }
}
